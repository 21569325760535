import React, {useState} from 'react';
import ResidenceFilterPageSection from '@hoffman/shared/src/components/page-sections/ResidenceFilterPageSection';
import ResidenceFloorGridBlock from '@hoffman/shared/src/components/page-sections/ResidenceFloorGridBlock';
import ResidenceFloorCardBlock from '@hoffman/shared/src/components/page-sections/ResidenceFloorCardBlock';
import {useStaticQuery, graphql} from 'gatsby';
import commafy from '@hoffman/shared/src/utils/commafy';
import Select from '@hoffman/shared/src/components/Input/select';
import Section from '@hoffman/shared/src/components/Section';
import SectionBackgroundGroup from '@hoffman/shared/src/components/SectionBackgroundGroup';
import SectionPadding from '@hoffman/shared/src/components/SectionPadding';
import Headline from '@hoffman/shared/src/components/TextFeatures/headline';
import styled from 'styled-components';

const RESIDENCE_LIST_VIEW_QUERY = graphql`
  query ResidenceListView {
    residenceCopy: sanityResidenceCopy(_id: {eq: "bf402c41-def1-420b-8b41-e2af29948966"}) {
        _rawBody
          }
   
    
    beds: allSanityBedroom(sort: {fields: bedroomNumber}) {
      nodes {
        id
        bedroomName
      }
    }
    floors: allSanityFloor(sort: {fields: floorNumber}) {
      nodes {
        id
        floorName
      }
    }
    units: allSanityUnit(filter: {unitAvailable: {eq: true}}) {
      nodes {
        unitName
        unitSlug {
          current
        }
        unitAvailable
        unitBalconySqFt
        unitInteriorSqFt
        unitFloor {
          id
          floorName
        }
        unitBeds {
          id
          bedroomName
        }
        unitBaths {
          id
          bathroomName
        }
        unitPDF {
          asset {
            url
          }
        }
        unitImage {
          asset {
            fluid(maxWidth: 625) {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
          }
        }
      }
    }
  }
`;
// TODO: Fix typegen to properly usee GatsbyTypes
const ResidenceListView = (props) => {
  const {beds, floors, units,residenceCopy} = useStaticQuery(RESIDENCE_LIST_VIEW_QUERY);
  const [currentFloorId, setFloorId] = useState('all');
  const [currentBedroomId, setBedroomId] = useState('all');
  //Query above selects the specific ID in the database for 
  //this copy area which is now controlled by the CMS
  const descriptionCopy = residenceCopy._rawBody;
  console.log(descriptionCopy, "copy");
  const handleFloorUpdate = (event) => {
    event.preventDefault();
    setFloorId(event.target.value);
  };

  const handleBedroomUpdate = (event) => {
    event.preventDefault();
    setBedroomId(event.target.value);
  };

  const sqOrder = units.nodes.sort((b, a) => {
    // sq ft, asc
    return b.unitInteriorSqFt - a.unitInteriorSqFt;
  });

  const unitResults = sqOrder.reduce((results, unit, i) => {
    if (currentFloorId === 'all' || currentFloorId === unit.unitFloor.id) {
      if (currentBedroomId === 'all' || currentBedroomId === unit.unitBeds.id) {
        return results.concat(
          <ResidenceFloorGridBlock key={i}>
            <ResidenceFloorCardBlock
              room={unit.unitName}
              bed={unit.unitBeds.bedroomName}
              bath={unit.unitBaths.bathroomName}
              balcony={commafy(unit.unitBalconySqFt) + ' Sq. Ft.'}
              floor={unit.unitFloor.floorName}
              sqFt={commafy(unit.unitInteriorSqFt) + ' Sq. Ft.'}
              ctaText="View"
              ctaLink={unit.unitPDF.asset.url}
              image={unit.unitImage.asset.fluid}
            />
          </ResidenceFloorGridBlock>,
        );
      }
    }
    return results;
  }, []);

  return (
    <div>
      <div>
        <form>
          <Section themeName="DARK">
            <SectionBackgroundGroup>
              <SectionPadding>
                <ResidenceFilterPageSection descriptionCopy={descriptionCopy}>
                  <Select
                    onChange={handleBedroomUpdate}
                    name="bedroom"
                    borderColor="grey"
                    text="Text"
                    placeholderColor="white"
                    required={false}
                    defaultValue="all"
                    defaultOptionText="All Bedrooms"
                    options={beds.nodes.sort().map((bed) => bed.bedroomName)}
                    valueID={beds.nodes.sort().map((bed) => bed.id)}
                  />
                  <Select
                    onChange={handleFloorUpdate}
                    name="floor"
                    borderColor="grey"
                    text="Text"
                    placeholderColor="white"
                    required={false}
                    defaultValue="all"
                    defaultOptionText="Any Floor"
                    options={floors.nodes.map((floor) => floor.floorName)}
                    valueID={floors.nodes.map((floor) => floor.id)}
                  />
                </ResidenceFilterPageSection>
              </SectionPadding>
            </SectionBackgroundGroup>
          </Section>
        </form>
      </div>
      <ResultContainer>
        {unitResults.length > 0 ? (
          <SectionBackgroundGroup>
            {/* <Headline
              text={`Showing ${unitResults.length} Plans`}
              size="37px"
            /> */}
            <SectionPadding px="narrow">{unitResults}</SectionPadding>
          </SectionBackgroundGroup>
        ) : (
          <Headline text={'No Units Found'} size="37px" />
        )}
      </ResultContainer>
    </div>
  );
};

export default ResidenceListView;

const ResultContainer = styled.div`
  h1 {
    margin-top: 0;
    text-align: center;
    padding-bottom: 100px;
  }

  div {
    h1 {
      margin-top: 0;
      text-align: center;
      padding-top: 70px;
      padding-bottom: 0px;
    }
  }
`;
