import React from 'react';
import styled from 'styled-components';

const ResidenceFloorGridBlock = styled.div`
  position: relative;
  display: flex;
  margin: 40px 0;
  flex-wrap: nowrap;
  color: ${({theme}) => theme.colors.text};
  font-size: ${({theme}) => theme.fontSizes[3]}px;

  :nth-child(odd) {
    justify-content: flex-start;
    margin-left: max(0px, (100vw - 1280px) * 0.6);
  }

  :nth-child(even) {
    justify-content: flex-end;
    margin-right: max(0px, (100vw - 1280px) * 0.4);
  }

  @media (max-width: ${({theme}) => theme.breakpoints[0]}) {
    padding: inherit;
    text-align: center;
    margin: 80px 0;
    flex-direction: column;
    align-items: center;

    :nth-child(odd),
    :nth-child(even) {
      justify-content: flex-start;
    }
  }
`;

export default ResidenceFloorGridBlock;
