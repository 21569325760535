import React, {Fragment} from 'react';
import styled from 'styled-components';
import {ArrowCta} from '@hoffman/shared/src/components/Cta/cta';
import Img from 'gatsby-image';

const ResidenceFloorCardBlock = ({
  room,
  bed,
  bath,
  balcony,
  floor,
  sqFt,
  ctaText,
  ctaLink,
  image,
}) => {


  const viewClick = () => {
    const payload = {
      'event': 'view_floor_plan',
      'room_number': room,
      'beds': bed,
      'baths': bath,
      'approx_sq_ft': sqFt,
      'balcony_sq_ft': balcony
    }
    window.dataLayer.push(payload);
  }
  return (
    <Fragment>
      <DescriptionContainer>
        <FloorNumber>{room}</FloorNumber>
        <InformationContainer>
          <Line />
          <DetailInformation>
            {bed} / {bath}
            <Fragment>
              <br />
              Balcony
            </Fragment>
          </DetailInformation>
          <DetailInformation>
            Approx. <br />
            {sqFt}
          </DetailInformation>
          <DetailInformation>
            Balcony
            <br />
            {balcony}
          </DetailInformation>
        </InformationContainer>
        <Line />
        <div id={'view-unit' + room}>
          <ArrowCta text={ctaText} color="grey" link={ctaLink} tar={true} clickHandler={ viewClick } />
        </div>
      </DescriptionContainer>
      <ImgContainer>
        <FitImg fluid={image} />
      </ImgContainer>
    </Fragment>
  );
};

export default ResidenceFloorCardBlock;

const DescriptionContainer = styled.div`
  margin-right: 30px;
  a {
    margin-top: 70px;
    padding: 8px;
  }
  @media (max-width: ${({theme}) => theme.breakpoints[0]}) {
    margin: auto;
    a {
      margin: 30px 0;
    }
  }
`;

const ImgContainer = styled.div`
  position: relative;
  /* todo: large screen responsive styling */
  width: 400px;
  min-height: 300px;
  @media (max-width: ${({theme}) => theme.breakpoints[0]}) {
    width: 300px;
  }
`;

const FitImg = styled(Img).attrs({
  // this overrides gatsby-img's inline style clobbering
  style: {position: 'absolute'},
})`
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  img {
    object-fit: scale-down !important;
  }
`;

const FloorNumber = styled.div`
  font-family: ${({theme}) => theme.fontFaces.heading};
  font-size: ${({theme}) => theme.fontSizes[6]}px;
  margin-bottom: ${({theme}) => theme.spaces[3]}px;
`;

const Line = styled.div`
  display: inline-block;
  border-bottom: 1px solid ${({theme}) => theme.baseColors.LIGHT_GRAY};
  margin: 10px 0;
  width: 100%;
  @media (max-width: ${({theme}) => theme.breakpoints[0]}) {
    display: inherit;
    display: inline-block;
    width: 100%;
  }
`;

const InformationContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media (max-width: ${({theme}) => theme.breakpoints[0]}) {
    align-items: center;
  }
`;

const DetailInformation = styled.div`
  font-family: ${({theme}) => theme.fontFaces.heading};
  font-size: ${({theme}) => theme.fontSizes[1]}px;
  font-weight: ${({theme}) => theme.fontWeights.light};
  text-transform: uppercase;
  line-height: 1.25em;
  margin: 0.4em 0;
`;
