import React from 'react';
import styled from 'styled-components';
import Select from '@hoffman/shared/src/components/Input/select';
import PortableText from "react-portable-text"

const ResidenceFilterPageSection = ({children,descriptionCopy}) => {
  return (
    <Container>
      <DescriptionContainer>
   
      <PortableText  content={descriptionCopy}></PortableText>
   
      </DescriptionContainer>
      <SelectContainer>{children}</SelectContainer>
    </Container>
  );
};

export default ResidenceFilterPageSection;

const Container = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 50% 50%;
  color: ${({theme}) => theme.colors.text};
  font-size: ${({theme}) => theme.fontSizes[3]}px;

  @media (max-width: ${({theme}) => theme.breakpoints[1]}) {
    grid-template-columns: 100%;
    font-size: ${({theme}) => theme.fontSizes[1]}px;
  }
`;

const DescriptionContainer = styled.div`
  font-family: ${({theme}) => theme.fontFaces.default};
  font-weight: ${({theme}) => theme.fontWeights.light};
  max-width: 520px;
  line-height: ${({theme}) => theme.lineHeights.body};
  div p{margin-bottom:15px;}
  div ul{list-style-type: circle; list-style-position: outside;
    margin-left:30px;
  };
`;

const SelectContainer = styled.div`
  select {
    padding: ${({theme}) => theme.spaces[2]}px 0;
    border-bottom: 0px solid white;
    color: white;
  }
  div {
    padding: 10px 5px;
  }

  @media (max-width: ${({theme}) => theme.breakpoints[1]}) {
    padding-top: 20px;
  }
`;
