import React from 'react';
import styled from 'styled-components';
import {ArrowCta, Cta} from '@hoffman/shared/src/components/Cta/cta';

interface CTABannerProps {
  headline: string;
  copy: string;
  cta: string;
  link: string;
  alt: string;
}

export const CTABannerSection = (props: CTABannerProps): JSX.Element => {
  return (
    <Container>
      <ContentContainer>
        <StyledSectionContainer>
          <StyledHeadline>{props.headline}</StyledHeadline>
          {props.copy.split('\n\n').map((txt, i) => (
            <StyledCopy key={i}>{txt}</StyledCopy>
          ))}
          <CtaContainer>
            <ArrowCta
              alt={props.alt}
              text={props.cta}
              link={props.link}
              color="#FFF8EF"
            />
          </CtaContainer>
        </StyledSectionContainer>
      </ContentContainer>
    </Container>
  );
};

CTABannerSection.defaultProps = {
  headline: 'Headline',
  copy: 'Lorem ipsum',
  cta: 'Learn more',
  link: 'https://google.com',
};

export default CTABannerSection;

const Container = styled.div`
  margin: 0px;
  font-weight: ${({theme}) => theme.fontWeights.light};
  background-color: ${({theme}) => theme.colors.background};
  color: ${({theme}) => theme.colors.text};
  @media (max-width: ${({theme}) => theme.breakpoints[0]}) {
    padding-top: 15px;
    padding-bottom: 25px;
  }
`;

const ContentContainer = styled.div`
  margin: 0 auto;
  max-width: 500px;
  text-align: center;
`;
const StyledSectionContainer = styled.div`
  -webkit-box-align: center;
  display: flex;
  flex-direction: column;
`;

const StyledHeadline = styled.div`
  font-family: ${({theme}) => theme.fontFaces.heading};
  font-size: ${({theme}) => theme.fontSizes[5]}px;
  letter-spacing: ${({theme}) => theme.letterSpacings[2]}px;
  @media (max-width: ${({theme}) => theme.breakpoints[0]}) {
    font-size: 34px;
  }
`;

const StyledCopy = styled.div`
  font-family: ${({theme}) => theme.fontFaces.default};
  font-size: ${({theme}) => theme.fontSizes[2]}px;
  font-weight: ${({theme}) => theme.fontWeights.light};
  letter-spacing: ${({theme}) => theme.letterSpacings[3]}px;
  margin: ${({theme}) => `${theme.spaces[2]}px 0 ${theme.spaces[1]}px`};
  @media (max-width: ${({theme}) => theme.breakpoints[0]}) {
    max-width: 250px;
    margin: 0 auto ${({theme}) => `${theme.spaces[3]}px`};
    padding: ${({theme}) => `${theme.spaces[2]}px 0 ${theme.spaces[2]}px}`};
    line-height: ${({theme}) => `${theme.lineHeights.body}em`};
  }
`;

const CtaContainer = styled.div`
  margin-top: ${({theme}) => theme.spaces[4]}px;
  display: block;
  width: 100%;
  @media (max-width: ${({theme}) => theme.breakpoints[0]}) {
    margin-top: ${({theme}) => theme.spaces[2]}px;
  }
`;
