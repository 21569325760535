import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import CTABannerPageSection from "@hoffman/shared/src/components/page-sections/CTABannerPageSection"
import Section from "@hoffman/shared/src/components/Section"
import SectionBackgroundGroup from "@hoffman/shared/src/components/SectionBackgroundGroup"
import SectionPadding from "@hoffman/shared/src/components/SectionPadding"

const HomeCTABannerBlock = props => {
  const { dataJson } = useStaticQuery<
    GatsbyTypes.HomeCTABannerBlockQuery
  >(graphql`
    query HomeCTABannerBlock {
      dataJson(slug: { eq: "home" }) {
        slug
        panels(name: "homeCTABanner") {
          name
          models {
            type
            fields {
              text
              url
              newTab
            }
          }
        }
      }
    }
  `)
  const items = dataJson.panels[0].models.reduce(
    (acc: object, m: { type: string; fields: any }) => {
      acc[m.type] = m.fields
      return acc
    },
    {}
  )
  return (
    <Section themeName="DARK">
      <SectionBackgroundGroup altBg={true}>
        <SectionPadding>
          <CTABannerPageSection
            headline={items.headline?.text}
            copy={items.copy?.text}
            cta={items.link && items.link.text}
            link={items.link && items.link.url}
          />
        </SectionPadding>
      </SectionBackgroundGroup>
    </Section>
  )
}

export default HomeCTABannerBlock
