import React from 'react';
import styled from 'styled-components';
import type {ChangeEventHandler} from 'react';

interface OptionProps {
  name: string;
  value: string;
}
export interface SelectProps {
  name: string;
  text: string;
  valueID: string;
  defaultValue: string;
  required?: boolean;
  defaultOptionText?: string;
  options: OptionProps[] | string[];
  borderColor: string;
  placeholderColor: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
}

export const Select = (props: SelectProps): JSX.Element => {
  const {
    name,
    text,
    required,
    onChange,
    defaultValue,
    defaultOptionText,
    options,
    valueID,
    borderColor,
    placeholderColor,
  } = props;

  const optionElements = options.reduce(
    (acc: OptionProps[], op: OptionProps | string) => {
      if (typeof op === 'string') {
        return acc.concat({name: op, value: valueID});
      } else if (op.name !== null && op.value !== null) {
        return acc.concat(op);
      } else {
        console.warn(
          `${JSON.stringify(
            op,
          )} is not of type 'string' nor {name: string; value: string}`,
        );
        return acc;
      }
    },
    [],
  ) as Array<OptionProps>;

  return (
    <SelectContainer borderColor={borderColor}>
      <SelectBox
        name={name}
        text={text}
        defaultValue={'default'}
        placeholderColor={placeholderColor}
        onChange={onChange}
      >
        <option value={defaultValue}>
          {defaultOptionText || 'Please Choose One'}
        </option>
        {optionElements &&
          optionElements.map((op: OptionProps, i) => (
            <option key={i} value={op.value[i]}>
              {op.name}
            </option>
          ))}
      </SelectBox>
    </SelectContainer>
  );
};

Select.displayName = 'Select';

Select.defaultProps = {
  placeholderColor: 'black',
  borderColor: 'black',
  required: false,
  arrow: false,
};

export default Select;

const SelectBox = styled.select`
  display: block;
  width: 100%;
  line-height: inherit;
  font-family: ${({theme}) => theme.fontFaces.default};
  border: 0px;
  font-size: ${({theme}) => theme.fontSizes[2]}px;
  font-weight: ${({theme}) => theme.fontWeights.light};
  padding: 8px 0px;
  background-color: transparent;

  option {
    background-color: ${({theme}) => theme.colors.background};
  }

  &::placeholder {
    color: ${(props: SelectProps) => props.placeholderColor};
  }
`;

const SelectContainer = styled.div`
  display: flex;
  border-bottom: 2px solid ${(props: SelectProps) => props.borderColor};
`;
